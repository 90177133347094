<template color="tapiz" class="pa-0">

<v-container class="pa-0">

  

   <VistaRegion v-if="rol_actual=='ADMIN'" />

  <VistaPais v-else />

</v-container> 
    
</template>

<script>


import { mapMutations, mapState, mapActions } from "vuex";

import VistaPais from './DashPais.vue';
import VistaRegion from './DashRegional.vue';



export default {
     components:{
      VistaPais,
      VistaRegion
    },
    data: () => ({

      rol_actual:'',


    }),
    computed:{
        ...mapState(['empresaid', 'token','usuario']),


    },
    watch:{

    },
    created () {

     this.rol_actual=this.usuario.rol;

    },
    methods:{


        ...mapActions(['salir']),

         cerrar(){
              this.dialogExit=false;
              localStorage.removeItem('usuario');
              localStorage.removeItem('token');
              
              this.salir();
            },


        ...mapMutations(['mostrarLoading','ocultarLoading']),


    }
}
</script>