
<template>
    <div class="mx-1 mt-2">
        <v-row>

            <v-col cols="3">

        <v-card width="100%" class="mt-0">
          <v-card-title>
            <span>Monitoreo de Exportacion</span>

            <v-tooltip bottom >
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  v-bind="attrs"  v-on="on" color="primary" @click="dialogExit=true"> 
                  <v-icon color="indigo">logout</v-icon>
                </v-btn>
              </template>
              <span>Salir de aplicacion</span>
          </v-tooltip>

          </v-card-title>
          <v-flex md12 lg10 mx-4>
                        <v-select v-model="id_pais_activo" dense 
                        outlined :items="PaisesArray" @change="listar()"  label="Pais"></v-select>
             </v-flex>

          <v-card-text>
              
              <v-row>
  
                  <v-col cols="12" class="mt-n7">
                    <div class="d-flex flex-row mt-0">
                    <v-flex xs2 sm2 md2 lg2 xl2 class="mt-n5">
                        <v-checkbox
                        v-model="checkboxTodo"
                        label="Todo"
                        @change="seleccionarTodo()"
                        ></v-checkbox>
                    </v-flex>
                    <v-flex xs7 sm7 md7 lg7 xl7 ml-9>
                       <v-text-field  v-model="searchtx"  dense single-line hide-details  prepend-inner-icon="search" 
                           label="Buscar" ></v-text-field>
                     </v-flex>
                    </div>

                      <v-data-table
                          :height="tableHeight"
                          fixed-header
                          :headers="headers"
                          :items="vehiculosTercerizados"
                          item-key="id_avl"
                          :search="searchtx"
                          class="elevation-1" 
                      >

                      <template v-slot:[`item.visible`]="{ item }">
                                        <v-checkbox density="compact"
                                          v-model="item.visible"
                                          @click="cambioVisible(item)"
                                        ></v-checkbox>    
                                      </template>

                        <template v-slot:[`item.nombre`]="{ item }"> 
                              <span style="font-size: 10px !important;">{{item.nombre}} </span>
                              <v-icon v-if="parseFloat(item.velocidad) >0" color="green" small @click="ubicar(item)">near_me</v-icon>
                              <v-icon v-else color="blue" small  @click="ubicar(item)">stop_circle</v-icon>
                          </template>
  
                       
                      </v-data-table>


                      <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
                     </v-dialog>
  
                  </v-col>
  
                
  
  
              </v-row>
  
              
    
  
          </v-card-text>
         </v-card>
            </v-col>


            <v-col cols="8" class="ml-n6 mt-2">
                <v-card width="100%" class="mt-0">

                    <GmapMap ref="mapRef" :style="mapStyle" :zoom="zoomMap" :center="centerMap">

                        <GmapMarker :key="index"  v-for="(m, index) in markers"
                        :position="m.position"
                        :clickable="true"
                        :draggable="false"
                        @click="m.window_open=true"
                        :icon="{ url:m.icono }"      
                        @mouseover="showByIndex = index;"
                        @mouseout="showByIndex = null"
                     >

                    <!-- se abre cuando pasamos mouse en el marcador-->
                    <gmap-info-window :opened="showByIndex === index  || m.window_open==true" @closeclick="m.window_open=false">
                        <div v-html="m.texto"></div>
                    </gmap-info-window>

                    </GmapMarker>



                    <!-- se abre cuandio damos click en algo-->
                    <gmap-info-window  :position="infoWindowPos" :opened="infoWinOpen" @closeclick="infoWinOpen=false">
                       <div v-html="infoWindowText"></div>
                    </gmap-info-window>


                     </GmapMap>



            </v-card>
               
           </v-col>
        </v-row>
  
    
    </div>

    
  </template>

<script>

import axios from 'axios';

import { mapState,mapMutations,mapActions } from "vuex";
    

export default {
      components:{
        
    
         },
    data () {
      return {
        tableHeight: 420,
        infoWinOpen:false,
        infoWindowPos:{lat: 13.7196949, lng: -89.2034191},
        infoWindowText:'',

        centerMap:{lat: 13.7196949, lng: -89.2034191},
        zoomMap:9,
        
        showByIndex:null,
        itemsPerPage:10,
        id_pais_activo:'',
        PaisesArray:[],
        markers:[],

        map: null,
   
        checkboxTodo:true,
        vehiculosTercerizados:[],
        headers:[ { text: '', value: 'visible'}, { text: 'Nombre', value: 'nombre'}, { text: 'Placa', value: 'placa'} ],
        searchtx:'',

        contadorEjecuciones:0,
        dialogExit:false,

        mapStyle: {
                height: '670px', // Default height
                width: '950px', // Default width
                position: 'relative',
                zIndex: 0,
                borderStyle: 'groove',
                marginTop: '0px',
                marginLeft: '5px',
                marginRight: '15px',
            },

      

      }
    },
     computed:{
        ...mapState(['empresaid', 'token','usuario']),

    },watch:{

    },

     created () {
        this.listarPaises();
     
    },

    mounted(){

        this.updateTableHeight();
        window.addEventListener('resize', this.updateTableHeight);

        this.updateMapDimensions();
        window.addEventListener('resize', this.updateMapDimensions);

         this.$refs.mapRef.$mapPromise.then((map) => {
                map.panTo({lat: 13.7196949, lng: -89.2034191})  
                })
     },
     beforeDestroy() {
            window.removeEventListener('resize', this.updateTableHeight);
            window.removeEventListener('resize', this.updateMapDimensions);
    },

    methods:{ 

        ...mapMutations(['mostrarLoading','ocultarLoading']),

        ...mapActions(['salir']),

        updateMapDimensions() {
            // Calculate available height and width (example: subtract some pixels for other content)
            const height = window.innerHeight - 60; // Adjust based on your layout
            const width = window.innerWidth - 90; // Adjust based on your layout
            this.mapStyle.height = `${height}px`;
            this.mapStyle.width = `${width}px`;
            },


        updateTableHeight() {
                // Calculate available height (example: screen height minus 200 pixels)
                this.tableHeight = window.innerHeight - 320;
                },

            cerrar(){
                this.dialogExit=false;
                localStorage.removeItem('usuario');
                localStorage.removeItem('token');
                
                this.salir();
            },

            listarPaises () {

                    let config={headers:{token:this.token}};
                    this.mostrarLoading({titulo:'Accediendo a datos'});

                    let me=this;

                    let RespuestaPaises=[];

                    axios.get('paises',  config
                    ).then(function(response){
                        console.log(response);
                        RespuestaPaises=response.data;

                        RespuestaPaises.map(function(x){
                            me.PaisesArray.push({text:x.nombre_pais, value:x._id})
                        });

                        me.id_pais_activo=me.PaisesArray[0].value;

                        me.ocultarLoading();

                        me.ConsumirVehiculos();

                        
                    }).catch(function(error){
                    console.log(error);
                    });




            },
            
    

        ConsumirVehiculos(){

            let arregloCheckAntes=[];

                        if(this.contadorEjecuciones==0){
                                 this.vehiculosTercerizados=[];
                                 this.markers=[];
                                 this.vehiculosTercerizados=[];
                           }

                           if( this.markers.length>0 ){
                              arregloCheckAntes=this.markers;
                              this.markers=[];
                              this.vehiculosTercerizados=[];
                           }
                          
                          
                           let me=this;
                           let config={headers:{token:this.token}};
                            
                            axios.get(`vehiculos-tercerizados/${this.id_pais_activo}`,
                            config
                            ).then(function(response){

                               console.log(response.data);

                               let respuesta=response.data;
                              

                               respuesta.map(function(x){

                                x.visible=false;
                                    arregloCheckAntes.map(function(y){
                                        if(y.id==x._id){
                                            
                                            x.visible=true;
                                            me.markers.push({
                                                id:x._id,
                                                position: {
                                                    lat: x.latitud,
                                                    lng: x.longitud,
                                                },
                                                icono:x.icono,
                                                texto:`<p><strong>Unidad:</strong>${x.nombre}<br/><strong>Placa:</strong>${x.placa}<br/><strong>Fecha:</strong>${x.ultimaTx}<br/><strong> Velocidad:</strong>${x.velocidad} <br/><strong> Evento:</strong>${x.evento}  <p>`,
                                                window_open:false
                                                });
                                        }
                                    });

                              
                                me.vehiculosTercerizados.push(x);
                          

                               });

                               if(me.contadorEjecuciones==0){
                                    me.vehiculosTercerizados=respuesta;
                                    me.checkboxTodo=true;

                                //function para recargar consulta cada 30 segundos
                                me.intervalo_ejecucion=setInterval(() => {
                                                                  me.ConsumirVehiculos();
                                                                }, 30000);
                              
                                me.seleccionarTodo();
                               }

                       

                               me.contadorEjecuciones++;
                              
                            }).catch(function(error){ console.log(error); });

        },

        cambioVisible(item){


            if(item.visible==true){  //pintar en mapa

                this.markers.push({
                                        id:item._id,
                                                position: {
                                                    lat: item.latitud,
                                                    lng: item.longitud,
                                                },
                                                icono:item.icono,
                                                texto:`<p><strong>Unidad:</strong>${item.nombre}<br/><strong>Placa:</strong>${item.placa}<br/><strong>Fecha:</strong>${item.ultimaTx}<br/><strong> Velocidad:</strong>${item.velocidad} <br/><strong> Evento:</strong>${item.evento}  <p>`,
                                                window_open:false
                                                });

            }else{  //quitar de mapa
                let editedIndex = this.markers.indexOf(item);
                this.checkboxTodo=false;
                this.markers.splice(editedIndex, 1);

                
            }
        },

        seleccionarTodo(){

            let me=this;

            if(this.checkboxTodo==true){

                me.vehiculosTercerizados.map(function(x){

                    if(x.visible==false){

                        me.markers.push({
                                                id:x._id,
                                                position: {
                                                    lat: x.latitud,
                                                    lng: x.longitud,
                                                },
                                                icono:x.icono,
                                                texto:`<p><strong>Unidad:</strong>${x.nombre}<br/><strong>Placa:</strong>${x.placa}<br/><strong>Fecha:</strong>${x.ultimaTx}<br/><strong> Velocidad:</strong>${x.velocidad} <br/><strong> Evento:</strong>${x.evento}  <p>`,
                                                window_open:false    
                                                
                                                });

                        x.visible=true;
                    }
                    
                })

            }else{
                me.vehiculosTercerizados.map(function(x){
                    x.visible=false;
                })

                me.markers=[];

            }
        },


       ubicar(item){

        if(item.visible==false){
            item.visible=true;
            this.cambioVisible(item);
         }
                

        this.centerMap={lat: item.latitud, lng: item.longitud};
        this.zoomMap=14;

        this.infoWinOpen=true;
        this.infoWindowPos={ lat: item.latitud, lng: item.longitud};
        this.infoWindowText=`<p><strong>Unidad:</strong>${item.nombre}<br/><strong>Placa:</strong>${item.placa}<br/><strong>Fecha:</strong>${item.ultimaTx}<br/><strong> Velocidad:</strong>${item.velocidad} <br/><strong> Evento:</strong>${item.evento}  <p>`;

       },


     


     

  


     }
  }

</script>


<style>

.theme--light.v-data-table > .v-data-table__wrapper > table > tbody > tr > td{
   font-size: 11px !important;
}

text-start{
    font-size: 11px !important;
}

</style>




