<template color="tapiz" class="mx-1">

<v-row class="mx-1 mt-1" color="tapiz">

  <!-- $notify --->
  <notifications group="app" position="top left"  classes="my-notification"/>
  <!--v-btn  @click.prevent="playSound()" class="d-none d-sm-none d-md-none d-lg-none d-xl-none">Play Sound</v-btn-->


<v-col cols="12" class="pt-0 pb-0">


<v-row class="pt-0 pb-0 d-none d-sm-flex d-md-flex d-lg-flex d-xl-flex">
    <v-col cols="4" xs="4" sm="8"  md="10"  lg="10" xl="10">
     <v-icon class="mr-2 mt-1" slot="icon" color="red" size="25">public</v-icon>
    <span class="text-subtitle-2 mt-3 d-none d-md-inline d-sm-inline d-lg-inline d-xl-inline ">Vista de administracion regional</span>
    <span class="text-subtitle-2 mt-3 d-inline d-sm-none d-md-none d-lg-none d-xl-none">Regional</span>


    </v-col>

    <v-col cols="8" xs="8" sm="4" md="2" lg="2" xl="2" class="mb-1 pr-xs-0">
     
<v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
  <v-btn class="mx-1" icon v-bind="attrs"  v-on="on" small color="primary" @click="dialogInicio=true">
      <v-icon dark>
       today
      </v-icon>
    </v-btn>

    </template>
<span>Cambio fechas</span>
</v-tooltip>



   <v-btn color="info" text outlined  @click="CambiarVistaPais()" small><v-icon>flag</v-icon>Paises</v-btn>

    <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
              <v-icon dark>logout</v-icon>
            </v-btn>
          </template>
          <span>Salir de aplicacion</span>
      </v-tooltip>

    </v-col >
  </v-row> 




<!-- barra inicial horizontal en pantall movil-->
  <v-row class="pa-0 pt-0 pb-0 d-inline  d-sm-none d-lg-none d-xl-none d-md-none">
    
    <v-col cols="12" class="pa-0">
     <v-icon class="mr-1" slot="icon" color="red" size="25">public</v-icon>
    <span class="text-subtitle-2 mt-2 mx-1">Regional</span>

  

     
<v-tooltip bottom>
    <template v-slot:activator="{ on, attrs }">
  <v-btn class="mx-1" icon v-bind="attrs"  v-on="on" small color="primary" @click="dialogInicio=true">
      <v-icon dark>
       today
      </v-icon>
    </v-btn>

    </template>
<span>Cambio fechas</span>
</v-tooltip>



   <v-btn color="info" text outlined  @click="CambiarVistaPais()" small><v-icon>flag</v-icon>Paises</v-btn>

    <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
              <v-icon dark>logout</v-icon>
            </v-btn>
          </template>
          <span>Salir de aplicacion</span>
      </v-tooltip>

    </v-col >
  </v-row> 
 


 
</v-col>


 <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
                </v-dialog>


  <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Dashboard por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="Procesar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>

     

 <!----datos seccion general dashboard     Revisado-->


 <v-col cols="12"  xs="12" sm="2" md="1"  xl="1" lg="1" class="pl-md-0 pl-lg-0 pl-xl-0  pr-md-0 pr-lg-0 pr-xl-0  pt-sm-0 d-none d-sm-inline d-lg-flex d-xl-flex d-md-flex">
        

    <v-card>
      <v-card-title class="align-start pb-0">
        
        <span class="font-weight-semibold text-center">Datos</span>
        
      </v-card-title>

     

       
      
          <div class="d-flex align-center pt-0 pl-1">
                <v-avatar size="30" color="indigo" rounded  class="elevation-1">
                  <v-icon dark color="white" small>
                    local_shipping
                  </v-icon>
                </v-avatar>
                <div class="ms-1">
                  <span class="text-caption">
                    Vehiculos
                  </span>
                  <h4 class="text-md font-weight-semibold">
                    {{ numeroVehiculos }}
                  </h4>
                </div>
          </div>

          <div class="d-flex align-center pt-0 pl-1">
                  <v-avatar size="30" color="orange" rounded  class="elevation-1">
                    <v-icon dark color="white" small>
                      switch_account
                    </v-icon>
                  </v-avatar>
                  <div class="ms-1">
                    <span class="text-caption">
                      Conduct.
                    </span>
                    <h4 class="text-xl font-weight-semibold">
                      {{ numeroConductores }}
                    </h4>
                  </div>
          </div>


          <div class="d-flex align-center pt-0 pl-1">
                  <v-avatar size="30" color="green" rounded  class="elevation-1">
                    <v-icon dark color="white" small>
                      commute
                    </v-icon>
                  </v-avatar>
                  <div class="ms-1">
                    <span class="text-caption">
                      Flotas
                    </span>
                    <h4 class="text-xl font-weight-semibold">
                      {{ numeroFlotas }}
                    </h4>
                  </div>
          </div>


          <div class="d-flex align-center pt-0 pl-1">
                <v-avatar size="30" color="warning" rounded  class="elevation-1">
                  <v-icon dark color="white" small>
                    flag
                  </v-icon>
                </v-avatar>
                <div class="ms-1">
                  <span class="text-caption">
                    Paises
                  </span>
                  <h3 class="text-xl font-weight-semibold">
                    {{ numeroPaises }}
                  </h3>
                </div>
          </div>


          <div class="d-flex align-center pt-0 pl-1">
      
                <v-avatar color="indigo" rounded size="30" class="elevation-3">
                <v-icon small dark>car_repair</v-icon>
              </v-avatar>
              


                  <div class="ms-1">
                    <span class="text-caption">Mantt.</span>
                   
                    <h4 class="text-xl font-weight-semibold">
                     {{ numeroMantt }}
                    </h4>
                  </div>
          </div>

       
     
  </v-card>

 </v-col>


<!-- barra inicial horizontal en pantall movil  -->
  <v-col cols="12"  xs="12" sm="2" md="1"  xl="1" lg="1" class="pt-0 d-inline d-sm-none d-lg-none d-xl-none d-md-none">
        

    <v-card class="pt-0">
      <v-card-title>
        
        <span class="font-weight-semibold text-center">Datos Generales</span>
        
      </v-card-title>

      <v-card-text>

      
          <v-flex class="d-inline-flex mx-3">
                <v-avatar size="30" color="indigo" rounded  class="elevation-1">
                  <v-icon dark color="white" small>
                    local_shipping
                  </v-icon>
                </v-avatar>
                <div class="ms-1">
                  <span class="text-caption">
                    Vehiculos
                  </span>
                  <h4 class="text-md font-weight-semibold">
                    {{ numeroVehiculos }}
                  </h4>
                </div>
          </v-flex>

          <v-flex class="d-inline-flex">
                  <v-avatar size="30" color="orange" rounded  class="elevation-1">
                    <v-icon dark color="white" small>
                      switch_account
                    </v-icon>
                  </v-avatar>
                  <div class="ms-1">
                    <span class="text-caption">
                      Conduct.
                    </span>
                    <h4 class="text-xl font-weight-semibold">
                      {{ numeroConductores }}
                    </h4>
                  </div>
          </v-flex>

          <br/>

          <v-flex class="d-inline-flex mt-2 mx-2">
                  <v-avatar size="30" color="green" rounded  class="elevation-1">
                    <v-icon dark color="white" small>
                      commute
                    </v-icon>
                  </v-avatar>
                  <div class="ms-1">
                    <span class="text-caption">
                      Flotas
                    </span>
                    <h4 class="text-xl font-weight-semibold">
                      {{ numeroFlotas }}
                    </h4>
                  </div>
          </v-flex>


          <v-flex class="d-inline-flex mt-2 mx-2"> 
                <v-avatar size="30" color="warning" rounded  class="elevation-1">
                  <v-icon dark color="white" small>
                    flag
                  </v-icon>
                </v-avatar>
                <div class="ms-1">
                  <span class="text-caption">
                    Paises
                  </span>
                  <h3 class="text-xl font-weight-semibold">
                    {{ numeroPaises }}
                  </h3>
                </div>
          </v-flex>


          <v-flex class="d-inline-flex" xs6>
      
                <v-avatar color="indigo" rounded size="30" class="elevation-3">
                <v-icon small dark>car_repair</v-icon>
              </v-avatar>
              


                  <div class="ms-1">
                    <span class="text-caption">Mantt.</span>
                   
                    <h4 class="text-xl font-weight-semibold">
                     {{ numeroMantt }}
                    </h4>
                  </div>
          </v-flex>

       </v-card-text>
     
  </v-card>

 </v-col>


<!--- Excesos de velocidad  -->

     <v-col cols="12" xs="12" sm="8" md="5" lg="5" xl="5"  class="pl-sm-0 pl-md-0 pl-lg-0 pl-xl-0 pt-0">

              <v-card  elevation="4">

                <v-card-title>Excesos de velocidad
                    <v-chip color="info" class="mx-1" small >{{ numeroExcesos }}</v-chip>

                    <v-spacer></v-spacer>

                     <v-btn  color="info" class="mx-1" dark small @click="dialogExceso=true" outlined>Detalle</v-btn>
              

                
                    <!--v-icon small>speed</v-icon-->
                </v-card-title>

                <v-card-text>
                  <v-row>

                    <v-col cols="12">

                      <div v-for="(itemseveridad, index) in CategoriaGlobalExces" :key="`severidad-${index}`" class="d-inline-flex mx-2 pt-1">

                         <v-avatar size="30" :color="itemseveridad.color" rounded  class="elevation-1">
                                  <v-icon dark color="white" small>{{ itemseveridad.icono }}</v-icon>
                         </v-avatar>

                          <span class="ml-1">{{ itemseveridad.nombre }}</span>
                          <span class="font-weight-bold ml-1">{{ itemseveridad.valor }}</span>

                         

                      </div>
                    </v-col>

                    <v-col cols="12" class="mt-n5 pb-0 pl-0">
                        <div id="chart">
                            <apexchart type="bar"  height="210" :options="chartOptionsExcesos" :series="seriesExcesos"></apexchart>
                        </div> 

                    </v-col>
                  


                </v-row>

                </v-card-text>


              </v-card>


     </v-col>

<!---Dialogo Excesos de velocidad-->
 <v-dialog v-model="dialogExceso" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Excesos de velocidad
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogExceso=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-col cols="4" sm="12" md="4">
                   <v-select
                              v-model="pais_avl_Excesos"
                              :items="PaisesArray"
                              @change="ActualizarDetalleExcesos()"
                              label="Pais"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>

                   <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchExcesos"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Excesos de velocidad</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersExcesos"
                            :items="itemsExceso"
                            :items-per-page="5"
                            :search="searchExcesos"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{getDate(item.fecha)}} </span>
                              </template>


                              <template v-slot:[`item.ubicacion`]="{ item }">
                                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                </template>
                        
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogExceso=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>




<!--- Botones de panico -->

       <v-col cols="12" xs="12"  sm="4" md="3"  lg="3" xl="3" class="pt-0 pl-0 pr-0">

         <v-card elevation="4">

            <v-card-title>

                <span class="d-none d-lg-inline d-xl-inline d-md-inline">Botones de panico </span>
                     <span class="d-inline d-lg-none d-xl-none d-md-none">Panico</span>
              
              
              <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
               <v-chip  color="red" class="mx-1" dark small   v-bind="attrs"  v-on="on">{{ numeroBotonesValidos }}</v-chip>
               </template>
                <span>Validos</span>
              </v-tooltip>



                <v-tooltip bottom>
               <template v-slot:activator="{ on, attrs }">
               <v-chip  color="secondary" small v-bind="attrs"  v-on="on">{{ numeroBotonesInValidos }}</v-chip>
                </template>
                <span>Invalidos</span>
              </v-tooltip>
              <!--v-spacer></v-spacer>
               <v-icon small>campaign</v-icon-->
            </v-card-title>
             <v-card-text>


               <div id="chart">
                 <apexchart type="bar" height="205" :options="chartOptionsBotones" :series="seriesBotones"></apexchart>
               </div>


           
                <v-btn block color="red" class="mx-2" small @click="dialogBotones=true" outlined>Detalle</v-btn>
               


             </v-card-text>

         </v-card>

       </v-col>


 <!---Dialogo botones-->
   <v-dialog v-model="dialogBotones" max-width="900px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de botones de panico
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogBotones=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-col cols="4" sm="12" md="4">
                   <v-select
                              v-model="pais_avl_Botones"
                              :items="PaisesArray"
                              @change="ActualizarDetalleBotones()"
                              label="Pais"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>

                      <v-switch class="ml-5 mt-5"
                      v-model="switchTipos"
                      :label="LabelSwitch"
                    ></v-switch>

                       <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchBotones"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Botones de panico</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersBotones"
                            :items="itemsBotones"
                            :items-per-page="5"
                            :search="searchBotones"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{getDate(item.fecha)}} </span>
                              </template>


                              <template v-slot:[`item.ubicacion`]="{ item }">
                                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                </template>

                                <template v-slot:[`item.observacion`]="{ item }">
                                  <v-icon small class="mr-2" color="indigo" @click="editItemBoton(item)">edit</v-icon>
                                <span>{{  item.observacion }} </span>
                                <span class="caption">- {{ item.editado}}</span>
                              </template>
                        
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogBotones=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
   </v-dialog>



      <v-dialog   v-model="dialogEditPanico"  max-width="700px">

          <v-card>

            <v-toolbar color="fondo1">
            <v-toolbar-title class="white--text">Boton de panico : {{ editedItemBoton.vehiculo }} </v-toolbar-title>
            <v-spacer></v-spacer>
            <v-icon dark @click="dialogEditPanico=false">close</v-icon>
          </v-toolbar>



            <v-card-text>
              <v-container>

                <v-row>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Vehiculo"
                    v-model="editedItemBoton.vehiculo"
                    disabled
                 ></v-text-field>
                </v-col>

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Placa"
                    v-model="editedItemBoton.placa"
                    disabled
                 ></v-text-field>
                </v-col>

              

                <v-col cols="12" sm="6" md="6">
                  <v-text-field
                    label="Fecha"
                    v-model="editedItemBoton.fecha"
                    disabled
                 ></v-text-field>
                </v-col>

                  <v-col cols="12" sm="6" md="6">
                   

                    <input type="radio" :value="true" v-model="editedItemBoton.valido"> Valido
                    <input type="radio" :value="false" v-model="editedItemBoton.valido"> Invalido
 
                 
                </v-col>





                  <v-col cols="12" sm="6" md="12">
                    <v-textarea
                      background-color="grey lighten-2"
                      prepend-icon="description"
                      v-model="editedItemBoton.observacion"
                      label="Observacion"
                    placeholder="aqui ingrese observacion"
                      rows="2"
                    ></v-textarea>
                  </v-col>



                  
                </v-row>
              </v-container>
            </v-card-text>

            <v-card-actions>
            
              
              <v-btn color="blue darken-1" text @click="dialogEditPanico=false">
                Cancelar
              </v-btn>
              <v-btn color="primary" dark  @click="saveBoton">
                Guardar
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>



<!--- Ralenti  -->

    <v-col cols="12" xs="12" sm="4"  md="3" lg="3" xl="3" class="pt-0">

        <v-card  elevation="4">
          <v-card-title>Ralenti
             <v-chip class="mx-2"  small>{{ this.totalTiempoRalenti }}</v-chip> 

            <v-spacer></v-spacer>
            <v-icon small>timer</v-icon>
          </v-card-title>

          <v-card-text>

            <div id="chart">
             <apexchart type="polarArea" height="250" :options="chartOptionsRalenti" :series="seriesRalenti"></apexchart>
           </div>

          
            <v-btn block color="info" class="mx-2 d-none d-lg-inline d-xl-inline d-md-inline" dark small @click="dialogRalenti=true" outlined>Detalle</v-btn>
            
             <v-btn block color="info" class="mx-2 d-inline d-lg-none d-xl-none d-md-none mt-6 mb-6" dark small @click="dialogRalenti=true" outlined>Detalle</v-btn>
                    

          </v-card-text>

           


            </v-card>

    </v-col>



<!---Dialogo ralenti-->
 <v-dialog v-model="dialogRalenti" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Ralenti
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogRalenti=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-col cols="4" sm="12" md="4">
                   <v-select
                              v-model="pais_avl_Ralenti"
                              :items="PaisesArray"
                              @change="ActualizarDetalleRalenti()"
                              label="Pais"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>

                      <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchRalenti"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Ralenti</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersRalenti"
                            :items="itemsRalenti"
                            :items-per-page="5"
                            :search="searchRalenti"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{ConvertirFecha(item.fecha)}} </span>
                              </template>


                              <template v-slot:[`item.ubicacion`]="{ item }">
                                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                </template>
                        
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogRalenti=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>







<!--- paradas -->

    <v-col cols="12" xs="12" sm="5" md="3" lg="3" xl="3" class="pt-0 pl-sm-0">
         <v-card>
    <v-card-title class="align-start">
      <span class="d-none d-lg-inline d-xl-inline d-md-inline">Paradas Autorizadas</span>
      <span class="d-inline d-lg-none d-xl-none d-md-none">Paradas Auto.</span>
        <v-chip class="mx-2 " small>{{ totalParadasAuto }}</v-chip> 

      <v-spacer></v-spacer>

      <v-btn icon small class="mt-n2 me-n3 d-none d-lg-inline d-xl-inline d-md-inline">
        <v-icon small>pin_drop</v-icon>
      </v-btn>
    </v-card-title>

    <v-card-text class="pt-0">
     
      <apexchart
        :options="chartOptionsDataAuto"
        :series="chartDataAuto"
        height="210"
      ></apexchart>

     
      <v-btn block color="primary" outlined  @click="dialogParadas=true" small>
        Detalle
      </v-btn>
    </v-card-text>
  </v-card>
    </v-col>

<!--- detalle de paradas -->


 <v-dialog v-model="dialogParadas" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de paradas
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogParadas=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-col cols="4" sm="12" md="4">
                   <v-select
                              v-model="pais_avl_parada"
                              :items="PaisesArray"
                              @change="ActualizarDetallePa()"
                              label="Pais"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>

                  <v-spacer></v-spacer>

                  <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchPa"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

                
                    

                  

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center">
                            <span class="h5 align-center">Datos de paradas Autorizadas</span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersParadas"
                            :items="itemsParadas"
                            :items-per-page="5"
                            :search="searchPa"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{getDate(item.fecha)}} </span>
                              </template>


                              <template v-slot:[`item.autorizado`]="{ item }">
                                  <v-icon>
                                    {{ item.autorizado==true ? "done" : "label_off" }}
                                  </v-icon>
                                  </template>


                                    <template v-slot:[`item.ubicacion`]="{ item }">
                                      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                      </template>
                        
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogParadas=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>














<!--- paradas NO permitidas-->

          <v-col cols="12" xs="12" sm="5" md="3" lg="3" xl="3" class="pt-0 pl-sm-0">
             <v-card color="white">

                 <v-img  max-height="333px"  src="../assets/triangle-light.png">

                 <v-card-title class="justify-center">
                     
                     <span class="d-none d-lg-inline d-xl-inline d-md-inline">Paradas No permitidas</span>
                     <span class="d-inline d-lg-none d-xl-none d-md-none">No permitidas</span>

                     <v-chip class="mx-2" small>{{ this.totalParadasNoAuto }}</v-chip> 

                     
                     <v-spacer></v-spacer>
                  <v-icon small>unpublished</v-icon>
                     
                     </v-card-title>
                 <v-card-text class="pt-n3 mt-n4">

                  
                      
                        <div id="chartNo" class="pt-n3">
                            <apexchart class="pt-n2 mt-n3" type="bar"  height="230" :options="chartOptionsNoauto" :series="seriesNoauto"></apexchart>
                        </div>  
                         <v-btn color="red" class="mt-0 justify-center" outlined block @click="dialogParadasNo=true" small> Ver Detalle</v-btn>
  
                         

                          <!--v-col cols="4" class="pt-0">

                              <v-list flat class="pa-0">
                                
                                <v-list-item-group color="primary">
                                    <v-list-item v-for="(item, i) in itemsParadasPais" :key="i" class="pa-0">
                                   
                                    <v-list-item-content class="pa-0">
                                       

                                          <v-progress-linear
                                            :color="item.color"
                                            rounded
                                            value="100"
                                          ></v-progress-linear>
                                        
                                        <v-list-item-title v-text="item.pais" class="caption"></v-list-item-title>
                                       <v-list-item-subtitle v-html="item.valor"></v-list-item-subtitle>
                                    </v-list-item-content>

                                    </v-list-item>
                                </v-list-item-group>
                                </v-list>

                          </v-col-->

                          
                   

                   <!--v-divider class="mt-2"></v-divider>
                   <v-icon class="mr-2" small>schedule</v-icon>
                   <span class="caption grey--text font-weight-light">informacion de hace 15 minutos</span-->
                 </v-card-text>

                 </v-img>

             </v-card>
         </v-col>


<!---Dialogo paradas NO permitidas-->

  <v-dialog v-model="dialogParadasNo" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de paradas No permitidas
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogParadasNo=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-col cols="4" sm="12" md="4">
                   <v-select
                              v-model="pais_avl_paradaNo"
                              :items="PaisesArray"
                              @change="ActualizarDetallePaNo()"
                              label="Pais"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>

                  <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchPaNo"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de paradas No permitidas</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersParadasNo"
                            :items="itemsParadasNo"
                            :items-per-page="5"
                            :search="searchPaNo"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{getDate(item.fecha)}} </span>
                              </template>


                              <template v-slot:[`item.autorizado`]="{ item }">
                                  <v-icon>
                                    {{ item.autorizado==true ? "done" : "label_off" }}
                                  </v-icon>
                                  </template>


                                    <template v-slot:[`item.ubicacion`]="{ item }">
                                      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                      </template>
                        
                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogParadasNo=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>




<!--- Combustible -->

         <v-col cols="12" xs="12" sm="8" md="6" lg="6" xl="6" class="pt-0">

         <v-card elevation="4">

            <v-card-title>
              Rendimiento combustible

              <v-btn color="warning" class="mx-1" @click="dialogCombustible=true" small outlined>Ver detalle</v-btn>
                <v-spacer></v-spacer>
               <v-icon small class="d-none d-lg-inline d-xl-inline d-md-inline">local_gas_station</v-icon>
            </v-card-title>
             <v-card-text>


                 <v-col cols="12" class="d-inline-flex">

                      <div class="d-inline mx-1">

                         <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                            <v-avatar size="25" color="indigo" rounded  class="elevation-1"   v-bind="attrs"  v-on="on">
                                      <v-icon dark color="white" small>straighten</v-icon>
                            </v-avatar>
                              <span class="ml-1 text-caption"> {{ distancia_global }} km </span>

        
                            </template>
                          <span>Distancia total</span>
                       </v-tooltip>

                         
                      </div>


                      <!--div class="d-inline mx-1">
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                         <v-avatar size="25" color="accent" rounded  class="elevation-1" v-bind="attrs"  v-on="on">
                                  <v-icon dark color="white" small>local_gas_station</v-icon>
                         </v-avatar>
                          <span class="ml-1 text-caption">{{ galones_aprox_global }} Gl </span>

                            </template>
                              <span>Consumo Aprox segun AVL</span>
                       </v-tooltip>
                      </div-->



                         


                         <div class="d-inline mx-1">
                            <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">

                              <v-avatar size="25" color="secondary" rounded  class="elevation-1" v-bind="attrs"  v-on="on">
                                        <v-icon dark color="white" small>local_gas_station</v-icon>
                              </v-avatar>
                                <span class="ml-1 text-caption">{{ galones_asignados_global }} Gl</span>
                                </template>
                              <span>Combustible Ingresado total</span>
                       </v-tooltip>
                      </div>


                       <!--div class="d-inline mx-1">

                         <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">


                            <v-avatar size="25" color="success" rounded  class="elevation-1" v-bind="attrs"  v-on="on">
                                      <v-icon dark color="white" small>paid</v-icon>
                            </v-avatar>
                                <span class="ml-1 text-caption"> ${{ gasto_global }}</span>
                                 </template>
                              <span>Gasto segun Ingresado total</span>
                       </v-tooltip>
                      </div-->



                        <div class="d-inline mx-1">

                         <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">


                            <v-avatar size="25" color="success" rounded  class="elevation-1" v-bind="attrs"  v-on="on">
                                      <v-icon dark color="white" small>trending_up</v-icon>
                            </v-avatar>
                                <span class="ml-1 text-caption"> {{ eficiencia_global }}</span>
                                 </template>
                              <span>Eficiencia</span>
                       </v-tooltip>
                      </div>



                  </v-col>


                <div id="chart">
                        <apexchart type="bar" height="190" :options="chartOptionsCombustible" :series="seriesCombustible"></apexchart>
                </div>

             </v-card-text>
         </v-card>

         </v-col>



 <!---Dialogo combustible-->
   <v-dialog v-model="dialogCombustible" max-width="850px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de combustible
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogCombustible=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-col cols="4" sm="12" md="4">
                   <v-select
                              v-model="pais_avl_Combustible"
                              :items="PaisesArray"
                              @change="ActualizarDetalleCombutible()"
                              label="Pais"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>


                      <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchCombustible"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>



              

                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Combustible</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersCombustible"
                            :items="itemsCombustible"
                            :items-per-page="5"
                            :search="searchCombustible"
                            class="elevation-1">

                              <template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{getDate(item.fecha)}} </span>
                              </template>

        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogCombustible=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
   </v-dialog>







<!--- Mantenimientos  Aceite -->


         <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">


           <v-card elevation="5px">

              
            <div class="d-flex flex-sm-row flex-column">
               
              <div class="flex-grow-1">
                
                <v-card-title color="transparent">
                  <span class="me-3">Mtto Aceite</span>



      


                   <v-chip color="deep-purple accent-4" dark small>{{ numeroAceite }}</v-chip>
                  <v-spacer></v-spacer>
                 
                      <v-btn   color="deep-purple accent-4" @click="dialogAceite=true"  outlined small>
                        <v-icon left>build</v-icon> 
                        Detalle
                        </v-btn>

                
                </v-card-title>

                <v-card-text color="transparent" >

               

                  
                    <v-divider></v-divider>
                  
                  <v-list color="transparent">
                    <v-list-item
                      v-for="(data,index) in mantenimientos_global"
                      :key="data.img" color="transparent"
                      :class="`d-flex px-0 ${index > 0 ? 'mt-1':''}`"
                    >


            
                      <v-img
                        contain
                        max-height="30"
                        max-width="30"
                        :src="require(`../assets/${data.img}`)"
                        class="mx-2"
                      ></v-img>

                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <div class="me-auto pe-2">
                          <h4 class="font-weight-semibold">
                            {{ data.titulo }}
                          </h4>
                          <span class="text-xs">{{ data.subtitulo }}</span>
                        </div>

                        <span class="font-weight-semibold success--text">{{ data.cantidad_aceite }}</span>
                      </div>
                    </v-list-item>
                  </v-list>


  
                </v-card-text>
             
              
              </div>
            </div>
           </v-card>

         </v-col>


 <!---Dialogo Aceite-->
   <v-dialog v-model="dialogAceite" max-width="800px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Mantenimientos pendientes: Aceite
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogAceite=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>
               

                  <v-col cols="4" sm="12" md="4">
                   <v-select
                              v-model="pais_avl_Aceite"
                              :items="PaisesArray"
                              @change="ActualizarDetalleAceite()"
                              label="Pais"
                              prepend-icon="flag"
                          ></v-select>
                  </v-col>

                      <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchAceite"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

              

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de mantt Aceite</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersAceite"
                            :items="itemsAceite"
                            :items-per-page="5"
                            :search="searchAceite"
                            class="elevation-1">

                              <template v-slot:[`item.creado`]="{ item }"> 
                              <span>{{getDate(item.creado)}} </span>
                              </template>

        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogAceite=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
   </v-dialog>

      






<!--- Mantenimientos  Llantas -->



          <v-col cols="12" xs="12" sm="4" md="4" xl="4" lg="4">

           <v-card elevation="5px">
            <div class="d-flex flex-sm-row flex-column">
              <div class="flex-grow-1">
                

                <v-card-title color="transparent">
                  <span class="me-3">Llantas</span>

                    <v-chip color="gray" dark small>{{ numeroLlanta }}</v-chip>

                    <v-spacer></v-spacer>
                  
                   <v-btn  class="ml-md-7 ml-lg-7 ml-xl-7" color="gray" @click="dialogLlanta=true" small  outlined>
                        <v-icon left>precision_manufacturing</v-icon> 
                        Detalle
                    </v-btn>

                </v-card-title>

                <v-card-text color="transparent">
                  
                    <v-divider></v-divider>

                  <v-list color="transparent">
                    <v-list-item
                      v-for="(data,index) in mantenimientos_global"
                      :key="`llantas`+data.img"
                      :class="`d-flex px-0 ${index > 0 ? 'mt-1':''}`"
                    >
                      <v-img
                        max-height="30"
                        max-width="30"
                         :src="require(`../assets/${data.img}`)"
                        class="me-3"
                      ></v-img>

                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <div class="me-auto pe-2">
                          <h4 class="font-weight-semibold">
                            {{ data.titulo }}
                          </h4>
                          <span class="text-xs">{{ data.subtitulo }}</span>
                        </div>

                        <span class="font-weight-semibold error--text">{{ data.cantidad_llantas }}</span>
                      </div>
                    </v-list-item>
                  </v-list>

                </v-card-text>

                
              </div>
            </div>
          </v-card>
         </v-col>

  <!---Dialogo Llantas-->
      <v-dialog v-model="dialogLlanta" max-width="800px">
              <v-card>

                  <v-toolbar color="fondo1">
                  <v-toolbar-title class="white--text">
                  Detalle de Mantenimientos pendientes: Llantas
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon dark @click="dialogLlanta=false">close</v-icon>
                </v-toolbar>



                <v-card-text>
                  <v-container>
                    <v-row>
                  

                      <v-col cols="4" sm="12" md="4">
                      <v-select
                                  v-model="pais_avl_Llanta"
                                  :items="PaisesArray"
                                  @change="ActualizarDetalleLlanta()"
                                  label="Pais"
                                  prepend-icon="flag"
                              ></v-select>
                      </v-col>


                      
                      <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchLlanta"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                      

                  

                        <v-col cols="12" sm="12" md="12">

                            <div class="align-center text-md-center mb-2">
                                <span class="h5 align-center"><strong>Datos de mantt Llantas</strong></span>
                            </div>
                            <v-divider></v-divider>
                            
                                <v-data-table
                                :headers="headersLlantas"
                                :items="itemsLlantas"
                                :items-per-page="5"
                                :search="searchLlanta"
                                class="elevation-1">

                                  <template v-slot:[`item.creado`]="{ item }"> 
                                  <span>{{getDate(item.creado)}} </span>
                                  </template>

            
                            </v-data-table>

                                      

                        </v-col>


                    </v-row>
                    
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogLlanta=false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
      </v-dialog>





<!--- Mantenimientos  Frenos -->


   <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">

        <v-card elevation="5px">

           
           
    <v-card-title class="align-start">
      <span>Matto Frenos</span>

       <v-chip color="red" dark class="mx-2" small>{{ numeroFreno }}</v-chip>

      <v-spacer></v-spacer>
      <v-btn  class="ml-7" color="red" @click="dialogFreno=true"  outlined small>
                        <v-icon left>car_repair</v-icon> 
                        Detalle
                        </v-btn>
     
    </v-card-title>

  
    <v-card-text>

        <v-divider></v-divider>

                  <v-list color="transparent">
                    <v-list-item
                      v-for="(data,index) in mantenimientos_global"
                      :key="`llantas`+data.img"
                      :class="`d-flex px-0 ${index > 0 ? 'mt-1':''}`"
                    >
                      <v-img
                        max-height="30"
                        max-width="30"
                         :src="require(`../assets/${data.img}`)"
                        class="me-3"
                      ></v-img>

                      <div class="d-flex align-center flex-grow-1 flex-wrap">
                        <div class="me-auto pe-2">
                          <h4 class="font-weight-semibold">
                            {{ data.titulo }}
                          </h4>
                          <span class="text-xs">{{ data.subtitulo }}</span>
                        </div>

                        <span class="font-weight-semibold error--text">{{ data.cantidad_frenos }}</span>
                      </div>
                    </v-list-item>
                  </v-list>

    </v-card-text>

              
              
  </v-card>
    </v-col>

 <!---Dialogo Frenos-->
      <v-dialog v-model="dialogFreno" max-width="800px">
              <v-card>

                  <v-toolbar color="fondo1">
                  <v-toolbar-title class="white--text">
                  Detalle de Mantenimientos pendientes: Frenos
                  </v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-icon dark @click="dialogFreno=false">close</v-icon>
                </v-toolbar>



                <v-card-text>
                  <v-container>
                    <v-row>
                  

                      <v-col cols="4" sm="12" md="4">
                      <v-select
                                  v-model="pais_avl_Freno"
                                  :items="PaisesArray"
                                  @change="ActualizarDetalleFreno()"
                                  label="Pais"
                                  prepend-icon="flag"
                              ></v-select>
                      </v-col>


                         
                      <v-spacer></v-spacer>

                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchFreno"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>



                      

                      

                  

                        <v-col cols="12" sm="12" md="12">

                            <div class="align-center text-md-center mb-2">
                                <span class="h5 align-center"><strong>Datos de mantt Frenos</strong></span>
                            </div>
                            <v-divider></v-divider>
                            
                                <v-data-table
                                :headers="headersFreno"
                                :items="itemsFreno"
                                :items-per-page="5"
                                :search="searchFreno"
                                class="elevation-1">

                                  <template v-slot:[`item.creado`]="{ item }"> 
                                  <span>{{getDate(item.creado)}} </span>
                                  </template>

            
                            </v-data-table>

                                      

                        </v-col>


                    </v-row>
                    
                  </v-container>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="blue darken-1" text @click="dialogFreno=false">
                    Cerrar
                  </v-btn>
                </v-card-actions>
              </v-card>
      </v-dialog>

 </v-row>


    
</template>
<script>



import axios from 'axios';
import { mapMutations, mapState, mapActions } from "vuex";
import VueApexCharts from 'vue-apexcharts';


export default {
     components:{
       apexchart: VueApexCharts
    },
    data: () => ({


      dialogExit:false,


      PaisesArray:[],
      ArrayVehiculos:[],
      ConductoresArray:[],
      ArregloMantenimientos:[],
      ArregloParadas:[],
      id_pais_activo:'',

      numeroPaises:0,
      numeroVehiculos:0,
      numeroFlotas:0,
      numeroConductores:0,
      numeroMantt:0,


      dialogInicio:false,


      menuFecha1:false,
      fecha_inicio:new Date().toISOString().substr(0, 10),
      menuFecha2:false,
      fecha_fin:new Date().toISOString().substr(0, 10),
      date: new Date().toISOString().substr(0, 10),




      nombre_empresa:'',


       categorias_paises:[],


      //-----------------campos para seccion de paradas -------------------------------------------


          chartOptionsDataAuto:{
            
                                                      chart: {
                                                        type: 'bar',
                                                        height: 210,
                                                        toolbar: {
                                                          show: false,
                                                          },
                                                      offsetX: -15,
                                                      },
                                                      plotOptions: {
                                                        bar: {
                                                          columnWidth: '40%',
                                                          distributed: true,
                                                          borderRadius: 8,
                                                          startingShape: 'rounded',
                                                          endingShape: 'rounded',
                                                        },
                                                      },
                                                      dataLabels: {
                                                        enabled: false
                                                      },
                                                      legend: {
                                                      show: false,
                                                    },
                                                      stroke: {
                                                        show: true,
                                                        width: 2,
                                                        colors: ['transparent']
                                                      },
                                                      xaxis: {
                                                        categories: []
                                                        
                                                      },
                                          yaxis: {
                                              show: true,
                                              tickAmount: 4,
                                            title: {
                                              text: ''
                                            },
                                            labels: {
                                              offsetY: 3,
                                            formatter: value => `${value}`,
                                            },
                                          },
                                          fill: {
                                            opacity: 1
                                          },
                                          tooltip: {
                                            y: {
                                              formatter: function (val) {
                                                return " " + val + " "
                                              }
                                            }
                                          },
                                          grid: {
                                      strokeDashArray: 12,
                                      padding: {
                                        right: 0,
                                      },
                                    },
                                
          },
        
          chartDataAuto:[],


            datos_paradas_auto:[],
            totalParadasAuto:0,
            dialogParadas:false,

            searchPa:'',

            headersParadas:[
                { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Conductor', value: 'nombre_conductor' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Evento', value: 'evento' },
                { text: 'Geocerca', value: 'geocerca'},
                { text: 'Autorizado', value: 'autorizado'},
                { text: 'Ubicacion', value: 'ubicacion' }
            ],
            itemsParadas:[],

            pais_avl_parada:'',//seleccionador en paradas para cambiar pais



      //------------------fin campos para seccion de paradas ----------------------------------------------




    //-----------------campos para seccion de paradas NO PERMITIDAS-------------------------------------------

        datos_paradas_Noauto:[],
        totalParadasNoAuto:0, 

          seriesNoauto:[{name: 'No Autorizadas', data:[1] }],
        
         chartOptionsNoauto: {
            chart: {
              type: 'bar',
              height: 230
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
             dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['Datos'],
            },
         },


          //items paradas por pais listado  
          itemsParadasPais:[],

          dialogParadasNo:false,
          pais_avl_paradaNo:'',  //seleccionador en paradas para cambiar pais en dialogo

          searchPaNo:'',

          headersParadasNo:[ { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Conductor', value: 'nombre_conductor' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Evento', value: 'evento' },
                { text: 'Geocerca', value: 'geocerca'},
                { text: 'Autorizado', value: 'autorizado'},
                { text: 'Ubicacion', value: 'ubicacion' }],
          itemsParadasNo:[],


    //----------------- FIN campos para seccion de paradas NO PERMITIDAS-------------------------------------------





   //-----------------campos para exceso por pais en severidad-------------------------------------------

       ArrayconfigPaises:[],
       ArregloExcesos:[],

       numeroExcesos:0,


       seriesExcesos: [
         {
          name: 'datos',
            data: [0]
            }
       ],


        chartOptionsExcesos:{
        
        chart: {
          type: 'bar',
          height: 210,
          width:400,
          stacked: true,
           toolbar: {
                show: false,
                },
        },
         colors: ['#56CA00', '#F44336', '#F44336', '#DFCB5E', '#303C9B', '#FFFD95','#C6D57E','#ED50F1', '#A8E7E9','#57CC99'],
                                     
        plotOptions: {
          bar: {
            horizontal: true,
              dataLabels: {
                  total: {
                    enabled: true,
                    offsetX: 0,
                    style: {
                      fontSize: '13px',
                      fontWeight: 900
                    }
                  }
                }
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff']
        },
        title: {
          text: ''
        },
        xaxis: {
          categories: ['datos'],
         
        },
       
        fill: {
          opacity: 1
        },
        legend: {
          position: 'bottom',
          horizontalAlign: 'center',
          offsetX: 40
        }
        },


        CategoriaGlobalExces:[],

        dialogExceso:false,
        pais_avl_Excesos:'',

        searchExcesos:'',
         headersExcesos:[ { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Conductor', value: 'nombre_conductor' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Evento', value: 'evento' },
                { text: 'severidad', value: 'severidad'},
                { text: 'velocidad', value: 'velocidad'},
                { text: 'Ubicacion', value: 'ubicacion' }],
          itemsExceso:[],









 //----------------- Inicio de campos grafica de circulos por ralenti-------------------------------------------


        //grafica de circulos por ralenti

        resumen_global:[],
        totalTiempoRalenti:0,

        seriesRalenti:[],
        datos_radial:[],

        chartOptionsRalenti:{},
        dialogRalenti:false,

         pais_avl_Ralenti:'',

         searchRalenti:'',
         headersRalenti:[ { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Distancia', value: 'distancia' },
                { text: 'hrs ralenti', value: 'tiempo_parado_enc'},
                { text: 'hrs mov', value: 'tiempo_mov' }],
          itemsRalenti:[],
          
  

       


 //----------------- Fin de campos grafica de circulos por ralenti-------------------------------------------




 //----------------- Inicio de campos Botones de panico-------------------------------------------

      
        pais_avl_Botones:'',
        dialogBotones:false,

        ArregloBotones:0,
        searchBotones:'',
        headersBotones:[ 
                { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Conductor', value: 'nombre_conductor' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Evento', value: 'evento' },
                { text: 'Velocidad', value: 'velocidad'},
                { text: 'Ubicacion', value: 'ubicacion' },
                { text: 'Observacion', value: 'observacion'}
                ],
        itemsBotones:[],

      

         editedItemBoton: {
            vehiculo: '',
            placa: '',
            flota: '',
            fecha: '',
            evento: '',
            direccion:'',
            observacion:'',
            editado:'',
            valido:true,
      },


         BotonesValidosAntes:0,

         numeroBotonesValidos:0,
         numeroBotonesInValidos:0,

         dialogEditPanico:false,
         switchTipos:true,
         LabelSwitch:'Validos',
         soundurl:'http://soundbible.com/mp3/Elevator%Ding-SoundBible.com-685385892.mp3',


        seriesBotones: [],

        chartOptionsBotones: {},

        





 //----------------- Inicio de campos de combustible-------------------------------------------

    
          //datos de combustible

          distancia_global:0,
          galones_aprox_global:0,
          galones_asignados_global:0,
          gasto_global:0,
          eficiencia_global:0,



          combustiblesGlobales:[],
          datos_combustible_global:[],
          eficiencia_paises:[],


          seriesCombustible: [{
            name: 'Distancia',
            data: []
          }, {
            name: 'Combustible ingresado',
            data: []
          }, {
            name: 'Eficiencia',
            data: []
          }],


          chartOptionsCombustible:{
            chart: {
              type: 'bar',
              height: 190,
               toolbar: { show: false }
            }, 
           
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['El Salvador', 'Nicaragua', 'Guatemala', 'Honduras', 'Panama'],
            },
            yaxis: {
              title: {
                text: 'Rendimiento'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "$ " + val + " thousands"
                }
              }
            }

          },


          dialogCombustible:false,
          pais_avl_Combustible:'',

           searchCombustible:'',

         headersCombustible:[ 
                { text: 'Vehiculo', value: 'vehiculo' },
                { text: 'Placa', value: 'placa' },
                { text: 'Fecha', value: 'fecha' },
                { text: 'Distancia', value: 'distancia' },
                { text: 'consumo', value: 'consumo'},
                { text: 'asignacion', value: 'asignacion' },
                { text: 'Eficiencia', value: 'eficiencia' },
                { text: 'costo', value: 'costo' }
                ], 
         itemsCombustible:[],


 //----------------- Seccion de mantenimiento--------------------------------


        //almacen de datos para todas las tablas de mantt
      mantenimientos_global : [],

      numeroAceite:0,
      numeroLlanta:0,
      numeroFreno:0,

      dialogAceite:false,
      pais_avl_Aceite:'',

      searchAceite:'',

      headersAceite:[
                      { text: 'Vehiculo', value: 'id_vehiculo_mant.nombre' },
                      { text: 'Placa', value: 'id_vehiculo_mant.placa' },
                      { text: 'Flota', value: 'id_vehiculo_mant.flota' },
                      { text: 'Tipo Mantto', value: 'id_tipo_mant.nombre_tipo' },
                      { text: 'Excedido km', value:'remanente'},
                      { text: 'Creado', value:'creado'}
                ],
      itemsAceite:[],


    dialogLlanta:false,
    pais_avl_Llanta:'',

    searchLlanta:'',
    headersLlantas:[
                      { text: 'Vehiculo', value: 'id_vehiculo_mant.nombre' },
                      { text: 'Placa', value: 'id_vehiculo_mant.placa' },
                      { text: 'Flota', value: 'id_vehiculo_mant.flota' },
                      { text: 'Tipo Mantto', value: 'id_tipo_mant.nombre_tipo' },
                      { text: 'Excedido km', value:'remanente'},
                      { text: 'Creado', value:'creado'}
    ],

     itemsLlantas:[],


     dialogFreno:false,
     pais_avl_Freno:'',
     searchFreno:'',
       headersFreno:[
                      { text: 'Vehiculo', value: 'id_vehiculo_mant.nombre' },
                      { text: 'Placa', value: 'id_vehiculo_mant.placa' },
                      { text: 'Flota', value: 'id_vehiculo_mant.flota' },
                      { text: 'Tipo Mantto', value: 'id_tipo_mant.nombre_tipo' },
                      { text: 'Excedido km', value:'remanente'},
                      { text: 'Creado', value:'creado'}
    ],

     itemsFreno:[],



      intervalo_ejecucion:null,

      //configuracion de excesos por flota, asi que debemos tener su segm
      arrayFlota:[],






    }),
    computed:{
        ...mapState(['empresaid', 'token','usuario']),


    },
    watch:{

        switchTipos(val){
          if(val==true){
             this.LabelSwitch='Validos';
             this.ActualizarDetalleBotones();
          }else{
               this.LabelSwitch='Invalidos';
               this.ActualizarDetalleBotones();
          }
      }

    },

     beforeDestroy () {

      clearInterval(this.intervalo_ejecucion);
      
   },
    created () {

         //console.log('fecha inicio vue:'+this.fecha_inicio);
         //console.log('inicio fecha local:'+this.CalcularFechaHoy());

        this.fecha_inicio=this.CalcularFechaHoy();
        this.fecha_fin=this.CalcularFechaHoy();

       this.Procesar();


    },
    methods:{


        ...mapActions(['salir']),

         cerrar(){
              this.dialogExit=false;
              localStorage.removeItem('usuario');
              localStorage.removeItem('token');
              
              this.salir();
            },


        ...mapMutations(['mostrarLoading','ocultarLoading']),

            Procesar(){

                let date1 = new Date(this.fecha_inicio);
                let date2 = new Date( this.fecha_fin);

                let  difference = date1.getTime() - date2.getTime();

                let days = Math.ceil(difference / (1000 * 3600 * 24));
                days = days * -1;
              

            //permitir reportes de 1 mes
            if(days < 32){

                  this.ArregloParadas=[];

                  this.datos_paradas_auto=[];
                  this.datos_paradas_Noauto=[];

                  //--datos en tablas detalles de dialogos
                  this.itemsParadas=[];
                  this.itemsParadasNo=[];

                  this.totalParadasAuto=0;
                  this.totalParadasNoAuto=0;

                  this.ArrayVehiculos=[];
                  this.numeroVehiculos=0;
                  this.ConductoresArray=[];
                  this.numeroConductores=0;

                  this.ArregloMantenimientos=[];
                  this.numeroMantt=0;

                    this.ArregloExcesos=[];
                    this.CategoriaGlobalExces=[];
                    this.numeroExcesos=0;

                    this.resumen_global=[];

                    this.ArregloBotones=[];

                    this.numeroBotonesValidos=0;
                    this.numeroBotonesInValidos=0;


                  this.listarPaises();

                }else{
                  alert('Solo puedes generar reportes de 1 mes o 31 dias');
                }



            },




          listarPaises () {

                        this.categorias_paises=[];
                        this.ArrayconfigPaises=[];

                        this.itemsParadasPais=[];

                        this.mantenimientos_global=[];

                        let config={headers:{token:this.token}};
                        this.mostrarLoading({titulo:'Accediendo a datos'});

                         let me=this;

                        let RespuestaPaises=[];

                        axios.get('paises',  config
                        ).then(function(response){
                         // console.log(response);
                          RespuestaPaises=response.data;


                          me.ArrayconfigPaises=RespuestaPaises;
                 
                          let contador_pais=0;

                          RespuestaPaises.map(function(x){
                                me.PaisesArray.push({text:x.nombre_pais, value:x._id});
                                me.categorias_paises.push(x.nombre_pais);

                                let color_cal=me.ObtenerColor(contador_pais);
                                 me.itemsParadasPais.push({'_id':x._id, 'pais':x.nombre_pais, 'valor':0, 'color':color_cal});
                                
                               

                                let img="car-oil.png";

                                 if(x.nombre_pais=='EL Salvador'){ img="El_Salvador.png";}
                                 if(x.nombre_pais=='Nicaragua'){ img="Nicaragua.png"; }
                                 if(x.nombre_pais=='Guatemala'){ img="Guatemala.png"; }
                                 if(x.nombre_pais=='Honduras'){ img="Honduras.png"; }
                                 if(x.nombre_pais=='Panama'){ img="Panama.png"; }
                                 if(x.nombre_pais=='Costa Rica'){ img="Costa_Rica.png"; }


                                 me.mantenimientos_global.push({
                                   '_id':x._id, 'img':img, 'titulo': x.nombre_pais, 'subtitulo': '',
                                    'cantidad_aceite': 0, 'cantidad_llantas': 0, 'cantidad_frenos': 0
                                 });



                                 
                                
                                
                                
                                contador_pais++;
                             });

                                  // pais por defecto en detalles
                                 me.pais_avl_parada=me.PaisesArray[0].value;
                                 me.pais_avl_paradaNo=me.PaisesArray[0].value;
                                 me.pais_avl_Excesos=me.PaisesArray[0].value;
                                 me.pais_avl_Ralenti=me.PaisesArray[0].value;
                                 me.pais_avl_Botones=me.PaisesArray[0].value;
                                 me.pais_avl_Combustible=me.PaisesArray[0].value;



                                  me.pais_avl_Aceite=me.PaisesArray[0].value;
                                  me.pais_avl_Llanta=me.PaisesArray[0].value;
                                  me.pais_avl_Freno=me.PaisesArray[0].value;






                          me.numeroPaises=RespuestaPaises.length;


                          me.chartOptionsDataAuto = {
                                                  chart: {
                                                    type: 'bar',
                                                    height: 210,
                                                    toolbar: {
                                                      show: false,
                                                      },
                                                  offsetX: -15,
                                                  },
                                                  plotOptions: {
                                                    bar: {
                                                      columnWidth: '40%',
                                                      distributed: true,
                                                      borderRadius: 8,
                                                      startingShape: 'rounded',
                                                      endingShape: 'rounded',
                                                    },
                                                  },
                                                  dataLabels: {
                                                    enabled: false
                                                  },
                                                  legend: {
                                                  show: false,
                                                },
                                                  stroke: {
                                                    show: true,
                                                    width: 2,
                                                    colors: ['transparent']
                                                  },
                                                  xaxis: {
                                                    categories: me.categorias_paises
                                                    
                                                  },
                                      yaxis: {
                                          show: true,
                                          tickAmount: 4,
                                        title: {
                                          text: ''
                                        },
                                        labels: {
                                          offsetY: 3,
                                        formatter: value => `${value}`,
                                        },
                                      },
                                      fill: {
                                        opacity: 1
                                      },
                                      tooltip: {
                                        y: {
                                          formatter: function (val) {
                                            return " " + val + " "
                                          }
                                        }
                                      },
                                      grid: {
                                  strokeDashArray: 12,
                                  padding: {
                                    right: 0,
                                  },
                                },
                            };

                            //console.log('paises');
                           // console.log(me.categorias_paises);



                          me.listarVehiculos();
                          me.listarFlotas();

                      
                          me.listarMantt();
                         
                          me.listarConductores();
                          me.ListarParadas();
                          me.listarBoton();
                          me.ListarIngresoCombustible();

                         




                            //cada 5 min se actualizaran los datos

                  


                            if((me.fecha_inicio==me.CalcularFechaHoy())&&(me.fecha_fin==me.CalcularFechaHoy())){

                                   clearInterval(me.intervalo_ejecucion);

                                   console.log('se actualziara cada 5 min')

                                  me.intervalo_ejecucion=setInterval(() => {
                                                      me.listarMantt();
                                                      me.listarExcesos();
                                                      me.listarConductores();
                                                      me.ListarParadas();
                                                      me.listarBoton();
                                                      me.ListarIngresoCombustible();
                                                    }, 300000);

                         }else{
                            console.log('pagina no se recargara debido a que las fechas no son validas');
                            clearInterval(me.intervalo_ejecucion);
                         }



                        me.ocultarLoading();

                        
                        }).catch(function(error){
                          console.log(error);
                        });
           },

                 //cada flota tiene su clasificacion de severidad
         listarFlotas(){

                     this.arrayFlota=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get('flotas-globales',
                    config
                    ).then(function(response){
                      
                        me.arrayFlota=response.data;

                        me.ocultarLoading();
                        me.listarExcesos();

                          console.log(me.arrayFlota);
                    }).catch(function(error){ console.log(error); });

         },



           listarVehiculos(){

                  this.ArrayVehiculos=[];

                 this.mostrarLoading({titulo:'Accediendo a datos'});
                 let config={headers:{token:this.token}};
                let me=this;
                axios.get(`vehiculos-all`,
                    config
                    ).then(function(response){
                       // console.log(response);
                        me.ArrayVehiculos=response.data;

                        let flotas=[];


                        me.ArrayVehiculos.map(function(x){

                          let flota_encontrada=false;
                          flotas.map(function(z){
                            if(x.flota==z){
                              flota_encontrada=true;
                            }
                          });

                          if(flota_encontrada==false){
                            flotas.push(x.flota);
                          }


                        });

                        me.numeroFlotas=flotas.length;




                        me.numeroVehiculos=me.ArrayVehiculos.length;
                   
                        me.ocultarLoading();
      
                    }).catch(function(error){ console.log(error); });

           },


           listarConductores () {

                  let config={headers:{token:this.token}};
                  this.mostrarLoading({titulo:'accediendo a datos'});
                  let me=this;
                  this.ConductoresArray=[];
                  this.numeroConductores=0;

                  axios.get(`conductores-all`, config
                  ).then(function(response){
                     // console.log(response);
                      me.ConductoresArray=response.data;

                       me.numeroConductores=me.ConductoresArray.length;
                     
                     //  me.listarMantt();
                    

                  }).catch(function(error){
                    console.log(error);
                  });

          },







         listarMantt(){

              this.ArregloMantenimientos=[];
              this.numeroMantt=0;

              this.numeroAceite=0;
              this.numeroLlanta=0;
              this.numeroFreno=0

               this.mostrarLoading({titulo:'Accediendo a datos'});
              let config={headers:{token:this.token}};
              let me=this;
              axios.get(`mantenimientos-all`,
                  config
                  ).then(function(response){
                     // console.log(response);
                      me.ArregloMantenimientos=response.data;
                      me.numeroMantt= me.ArregloMantenimientos.length;


                              //recorremos cada pais con mantt vacios 
                              me.mantenimientos_global.map(function(y){

                                    y.cantidad_aceite=0;
                                    y.cantidad_llantas=0;
                                    y.cantidad_frenos=0;


                                 me.ArregloMantenimientos.map(function(x){

                                   if(y._id==x.id_pais_mant._id){

                                       if(x.id_tipo_mant.clasificacion=='Aceite'){y.cantidad_aceite++; me.numeroAceite++;}
                                       if(x.id_tipo_mant.clasificacion=='llantas'){y.cantidad_llantas++; me.numeroLlanta++;}
                                       if(x.id_tipo_mant.clasificacion=='Frenos'){y.cantidad_frenos++; me.numeroFreno++;}

                                   }

                              });



                            });



                    me.ActualizarDetalleAceite();

                    me.ActualizarDetalleLlanta();

                    me.ActualizarDetalleFreno();

                     

                     me.ocultarLoading();
                  }).catch(function(error){ console.log(error); });

           },



         ListarParadas(){
          
           this.chartDataAuto=[];
           this.seriesNoauto=[{name: 'No Autorizadas', data:[] }];
           this.chartOptionsNoauto={};

            this.ArregloParadas=[];

            this.datos_paradas_auto=[];
            this.datos_paradas_Noauto=[];

            //--datos en tablas detalles de dialogos
            this.itemsParadas=[];
            this.itemsParadasNo=[];

            this.totalParadasAuto=0;
            this.totalParadasNoAuto=0;
           
               let config={headers:{ token:this.token}};
                let me=this;
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('paradas-global',{
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin
                              },
                            config
                            ).then(response=>{
                            
                             //   console.log(response);
                                me.ArregloParadas=response.data;

                                let respuesta=response.data;


                          //---llenando grafica de paradas autorizadas
                          let contador_localNO=0;

                        
                            me.ArrayconfigPaises.map(function(x){

                                       let contador=0;
                                       let contadorNo=0;

                                      respuesta.map(function(p){

                                        if(p.id_parada_pais._id==x._id){

                                          if(p.autorizado==true){
                                            contador++;
                                            me.totalParadasAuto++;

                                          }else if(p.autorizado==false){
                                              contadorNo++;
                                              contador_localNO++;
                                             

                                          }

                                          
                                        }

                                      });

                                      me.datos_paradas_auto.push(contador);
                                  
                                      me.datos_paradas_Noauto.push(contadorNo);

                                    //  console.log('pais:'+x._id)                                  

                                  });

                                  me.totalParadasNoAuto=contador_localNO;


                                
                                  me.chartDataAuto.push({name: 'Autorizadas',data: me.datos_paradas_auto});



                                  me.seriesNoauto=[{name: 'No Autorizadas', data:me.datos_paradas_Noauto }];
                                  
                                  


                                   me.chartOptionsNoauto= {
                                                    chart: {
                                                      type: 'bar',
                                                      height: 230,
                                                      toolbar: {
                                                        show: false,
                                                        },
                                                    },

                                                  
                                                      colors: ['#e74c3c', '#2ecc71', '#3498db', '#9b59b6', '#1abc9c',
                                                                '#FFFD95','#C6D57E','#ED50F1', '#A8E7E9','#57CC99'],
                                                      
                                                      plotOptions: {
                                                       bar: {
                                                          horizontal: false,
                                                          columnWidth: '55%',
                                                          endingShape: 'rounded'
                                                        },
                                                
                                                       },
                                                       dataLabels: {
                                                          enabled: false
                                                        },

                                                         stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent']
                                                          },
                                                          xaxis: {
                                                            categories: me.categorias_paises,
                                                          },


                                                       
                                                          legend: {
                                                                //position: 'bottom'
                                                                show: false
                                                              },

                                                              title: {
                                                                  text: '',
                                                              }
                                                          
                                                 };



                                  //llenamos la serie grafica 

                                  let contador_local=0;

                                   me.itemsParadasPais.map(function(x){

                                     x.valor=me.datos_paradas_Noauto[contador_local];
                                    contador_local++;

                                   });


                              //------datos de dialogo detale de paradas----------


                               me.ArregloParadas.map(function(x){

                                 if(x.id_vehiculo!=null){

                                 
                                 if((x.autorizado==true)&&(x.id_parada_pais._id==me.pais_avl_parada)){

                                        let geocerca='-';

                                        if((x.id_geocerca)&&(x.id_geocerca!=null)){
                                          geocerca=x.id_geocerca.nombre_geocerca;
                                        }
                                          
                                          
                                         /// console.log('valor:');
                                       //   console.log('nombre:'+x.id_vehiculo.nombre);

                                          me.itemsParadas.push({ 'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                          'nombre_conductor':x.id_conductor.nombre_conductor,'fecha':x.fecha,'evento':x.evento,
                                          'geocerca':geocerca,'autorizado':x.autorizado, 'latitud':x.latitud,
                                          'longitud':x.longitud});

                                   }else if((x.autorizado==false)&&(x.id_parada_pais._id==me.pais_avl_parada)){

                                          let geocerca='';

                                              if((x.id_geocerca)&&(x.id_geocerca!=null)){
                                              geocerca=x.id_geocerca.nombre_geocerca;
                                            }

                                    //pais_avl_paradaNo

                                     me.itemsParadasNo.push({ 'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                          'nombre_conductor':x.id_conductor.nombre_conductor,'fecha':x.fecha,'evento':x.evento,
                                          'geocerca':geocerca,'autorizado':x.autorizado, 'latitud':x.latitud,
                                          'longitud':x.longitud});



                                   }

                               }

                               });

                                                                   
                                me.ocultarLoading();
                                me.dialogInicio=false;
                                

                         }).catch(e=>{ console.log(e) });





           },

           ActualizarDetallePa(){

           console.log('cambiando datos de paradas en detalle');
           console.log(this.ArregloParadas);

                let me=this;
                 me.itemsParadas=[];

                me.ArregloParadas.map(function(x){


                                 if((x.autorizado==true)&&(x.id_parada_pais._id==me.pais_avl_parada)){

                                     let geocerca='';

                                            if(x.id_geocerca){
                                              geocerca=x.id_geocerca.nombre_geocerca;
                                            }

                                          me.itemsParadas.push({ 'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                          'nombre_conductor':x.id_conductor.nombre_conductor,'fecha':x.fecha,'evento':x.evento,
                                          'geocerca':geocerca,'autorizado':x.autorizado, 'latitud':x.latitud,
                                          'longitud':x.longitud});

                                   }

                               });
           },


           ActualizarDetallePaNo(){

               let me=this;
                 me.itemsParadasNo=[];

                me.ArregloParadas.map(function(x){


                                 if((x.autorizado==false)&&(x.id_parada_pais._id==me.pais_avl_paradaNo)){


                                     let geocerca='';

                                            if(x.id_geocerca){
                                              geocerca=x.id_geocerca.nombre_geocerca;
                                            }

                                          me.itemsParadasNo.push({ 'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                          'nombre_conductor':x.id_conductor.nombre_conductor,'fecha':x.fecha,'evento':x.evento,
                                          'geocerca':geocerca,'autorizado':x.autorizado, 'latitud':x.latitud,
                                          'longitud':x.longitud});

                                   }

                               });



           },


          ObtenerColor(numero){

            let color='#e74c3c';

            if(numero==0){
              color='#e74c3c';
            }else if(numero==1){
              color='#2ecc71';
            }else if(numero==2){
              color='#3498db';
            }else if(numero==3){
              color='#9b59b6';
            }else if(numero==4){
              color='#1abc9c';
            }else if(numero==5){
              color='#FFFD95';
            }else if(numero==6){
              color='#C6D57E';
            }else if(numero==7){
              color='#ED50F1';
            }else if(numero==8){
              color='#A8E7E9';
            }else{
              color='#57CC99';
            }

          return(color);


          },


         openGoogleMap(item){
            const urlSuffix = item.latitud +','+ item.longitud;
            window.open(
              "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
              "_blank"
            );
           },


        getDate(evaluar) {
            let date = new Date(evaluar),
            year = date.getFullYear(),
            month = (date.getMonth() + 1).toString(),
            formatedMonth = (month.length === 1) ? ("0" + month) : month,
            day = date.getDate().toString(),
            formatedDay = (day.length === 1) ? ("0" + day) : day,
            hour = date.getHours().toString(),
            formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
            minute = date.getMinutes().toString(),
            formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
            second = date.getSeconds().toString(),
            formatedSecond = (second.length === 1) ? ("0" + second) : second;
            return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
         },


         listarExcesos(){

          this.mostrarLoading({titulo:'listando datos'});


           let datos_local_barra=[];

           this.seriesExcesos=[
                {
                  name: 'datos',
                  data: [0]
                }
                ];

            this.ArregloExcesos=[];
            this.CategoriaGlobalExces=[];
            this.numeroExcesos=0;
            let me=this;


           //establecemos etiquetas de las clasificaciones de severidad a nivel de todos los paises

            let label_severidad=[];
      

            //label_severidad.push('Leve');
            // let diseno=me.ObtenerDisenoSeveridad(0);
            //me.CategoriaGlobalExces.push({'nombre':'Leve', 'valor':0,'color':diseno.color, 'icono':diseno.icono});
           // datos_local_barra.push({'name':'Leve', 'data':[]});


            label_severidad.push('Grave');
            let diseno=me.ObtenerDisenoSeveridad(1);
            me.CategoriaGlobalExces.push({'nombre':'Grave', 'valor':0,'color':diseno.color, 'icono':diseno.icono});
            datos_local_barra.push({'name':'Grave', 'data':[]});


            label_severidad.push('Critico');
            diseno=me.ObtenerDisenoSeveridad(2);
            me.CategoriaGlobalExces.push({'nombre':'Critico', 'valor':0,'color':diseno.color, 'icono':diseno.icono});
            datos_local_barra.push({'name':'Critico', 'data':[]});
          


              //pedimos los datos al api
              
           
               let config={headers:{ token:this.token}};
               
              

                  axios.post('eventos-global',{     
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin,
                                'tipo':'Exceso'
                              },
                            config
                            ).then(response=>{
                            
                               // console.log('datos excesos globales:'+response.data.length);

                               let respuesta=response.data;

                               respuesta.map(function(x){
                                  if(x.velocidad > 79){
                                    me.ArregloExcesos.push(x);
                                  }
                                });



                                

                                //recorremos la segmentacion de etiquetas recorriendo paises y excesos
                                datos_local_barra.map(function(x){

                              
                                    let contador_evento=0;

                                    me.ArrayconfigPaises.map(function(y){

                                        contador_evento=0;


                                        me.ArregloExcesos.map(function(z){


                                           let clasificacion=[];

                                      if((z.id_vehiculo) && (z.id_vehiculo.flota!=null)){

                                          //buscamos la segmentacion para esa flota de ese evento
                                          me.arrayFlota.map(function(b){
                                            if(b.nombre_flota==z.id_vehiculo.flota){
                                                clasificacion=b.clasificacion_velocidad;
                                            }
                                            });


                                          if(z.id_evento_pais==y._id){

                                                  clasificacion.map(function(a){
                                                    
                                                    if((a.nombre==x.name)&&(z.velocidad  >= a.desde)&&(z.velocidad  <=  a.hasta)){
                                                      if(z.velocidad){
                                                          contador_evento++;
                                                      }
                                                    }
                                                  });

                                        }


                                  }//fin si existe el vehiculo





                                      }); 

                                  //insertamos en detalle de barras locales valor de pais

                                  let datos_anterior=x.data;
                                  datos_anterior.push(contador_evento);
                                  x.data=datos_anterior;

                                 
                                 });




                                });

                           

                              

                                  
                              me.chartOptionsExcesos={
                                      
                                      chart: {
                                        type: 'bar',
                                        height: 210,
                                      
                                        stacked: true,
                                         distributed: false,
                                        toolbar: {
                                              show: false,
                                              },
                                      },
                                      colors: ['#56CA00', '#F44336', '#F44336', '#DFCB5E', '#303C9B', '#FFFD95','#C6D57E','#ED50F1', '#A8E7E9','#57CC99'],
                                      plotOptions: {
                                        bar: {
                                           rangeBarOverlap: true,
                                          horizontal: true,
                                          
                                           distributed: false,
                                            dataLabels: {
                                               hideOverflowingLabels: false,
                                                  
                                                }
                                        },
                                      },
                                      stroke: {
                                        width: 1,
                                        colors: ['#fff']
                                      },
                                      title: {
                                        text: ''
                                      },
                                      xaxis: {
                                        categories:me.categorias_paises,
                                       
                                      },
                                     
                                      fill: {
                                        opacity: 1
                                      },
                                      legend: {
                                        position: 'bottom',
                                        horizontalAlign: 'center',
                                        offsetX: 40
                                      }
                                      };


                                  me.seriesExcesos=datos_local_barra;

                                  console.log('datos a graficar:');

                                  console.log(datos_local_barra);









                                me.CategoriaGlobalExces.map(function(x){

                                  let total=0;


                                  

                                  datos_local_barra.map(function(y){

                                    if(x.nombre==y.name){
                                      let datos=y.data;

                                      for(let i of datos) total+=i;
                                      x.valor=total;

                                      me.numeroExcesos=total+parseInt(me.numeroExcesos);

                                    }

                                  });



                                });
                              
                         


                               

                                //me.numeroExcesos++;


                                 me.ActualizarDetalleExcesos();
                                 
                                 me.dialogInicio=false;
                             

                         }).catch(e=>{ console.log(e) });

         },

           ActualizarDetalleExcesos(){

            
              let me=this;



               
                 me.itemsExceso=[];

                  me.ArregloExcesos.map(function(x){

                    if(x.id_vehiculo!=null){

                  
                                 if(x.id_evento_pais==me.pais_avl_Excesos){


                                   let severidad='';

                                    let clasificacion=[];

                                     if((x.id_vehiculo) && (x.id_vehiculo.flota!=null)){

                                          //buscamos la segmentacion para esa flota de ese evento
                                          me.arrayFlota.map(function(b){
                                            if(b.nombre_flota==x.id_vehiculo.flota){
                                                clasificacion=b.clasificacion_velocidad;
                                            }
                                            });

                                     }


                                      clasificacion.map(function(a){
                                                    
                                                    if((x.velocidad  >= a.desde)&&(x.velocidad  <=  a.hasta)){
                                                      if(x.velocidad){
                                                          severidad=a.nombre;
                                                      }
                                                    }
                                                  });

                                          me.itemsExceso.push({ 'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                          'nombre_conductor':x.id_conductor.nombre_conductor,'fecha':x.fecha,'evento':x.evento,
                                          'severidad':severidad,'velocidad':x.velocidad, 'latitud':x.latitud,
                                          'longitud':x.longitud});

                                   }


                       }

                      });

                me.ocultarLoading();

           },

          ObtenerDisenoSeveridad(numero){


            let color='primary';
            let icono='error';

            if(numero==0){
              color='primary';
              icono='error';
            }else if(numero==1){
              color='success';
              icono='warning';
            }else if(numero==2){
              color='red';
              icono='bus_alert';
            }else if(numero==3){
              color='yellow';
              icono='report';
            }else{
              color='blue';
              icono='fmd_bad';
            }

          let diseno={'color':color, 'icono':icono};

          return(diseno);


          },

          ListarIngresoCombustible(){


                this.galones_asignados_global=0;
                this.gasto_global=0;

                this.eficiencia_global=0;
                this.eficiencia_paises=[];



                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'accediendo a datos'});
                this.combustiblesGlobales=[];
                this.datos_combustible_global=[];

                let me=this;

                 axios.post('combustibles-globales',
                      {
                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin
                       },
                  config
                  ).then(function(response){
                     // console.log(response);

                       me.combustiblesGlobales=response.data;
                        let eficiencia_promedio_global=[];


                          me.ArrayconfigPaises.map(function(x){

                            let asignacion_pais=0;
                             let costo_pais=0;
                            
                             let arreglo_eficiencia=[];

                                me.combustiblesGlobales.map(function(y){

                                  if(y.id_comb_pais._id==x._id){
                                    asignacion_pais=parseFloat(y.cantidad)+parseFloat(asignacion_pais);
                                    costo_pais=parseFloat(costo_pais)+parseFloat(y.costo);

                                    if(parseFloat(y.eficiencia)>0){
                                        arreglo_eficiencia.push(parseFloat(y.eficiencia).toFixed(2));
                                        console.log('eficiencia:'+parseFloat(y.eficiencia).toFixed(2));
                                      }
                                  }

                                });


                                me.datos_combustible_global.push(parseFloat(asignacion_pais).toFixed(2));

                                me.galones_asignados_global=parseFloat(asignacion_pais) + parseFloat(me.galones_asignados_global);
                                me.gasto_global=parseFloat(costo_pais) + parseFloat(me.gasto_global);


                                if(arreglo_eficiencia.length >0){
                                    me.eficiencia_paises.push(me.calculateAverage(arreglo_eficiencia));
                                    console.log('promedio:'+me.calculateAverage(arreglo_eficiencia));
                                    eficiencia_promedio_global.push(me.calculateAverage(arreglo_eficiencia));
                                }else{
                                   me.eficiencia_paises.push(0);
                                }

                               

                         });


                          me.galones_asignados_global=parseFloat(me.galones_asignados_global).toFixed(2);
                          me.gasto_global=parseFloat(me.gasto_global).toFixed(2);

                              if(eficiencia_promedio_global.length >0){
                                   me.eficiencia_global=me.calculateAverage(eficiencia_promedio_global);
                              }

                     me.ListarResumen();
                       
                  }).catch(function(error){ console.log(error); });

          },


         ListarResumen(){   //lista datos del dia actual

                //this.seriesRalenti=[];
               // this.chartOptionsRalenti={};

                this.distancia_global=0;
                this.galones_aprox_global=0;

               this.resumen_global=[];


                this.totalTiempoRalenti=0;
                this.datos_radial=[];
                let config={headers:{token:this.token}};
                this.mostrarLoading({titulo:'accediendo a datos'});
                let me=this;

                axios.post('resumenes-global',{
                      'desde':this.fecha_inicio,
                      'hasta':this.fecha_fin,
                }, config
                ).then(function(response){
                   // console.log(response);
                    me.resumen_global=response.data;

                    let distancias_paises=[];
                    let consumo_paises_avl=[]


                     me.ArrayconfigPaises.map(function(x){

                       let distancia_total_pais=0;
                       let consumo_total_pais_avl=0;

                       let total_pais=0;
                        me.resumen_global.map(function(y){

                          if(x._id==y.id_resumen_pais._id){

                           

                              if((y.tiempo_parado_enc !='00:00:00')
                              &&(y.tiempo_parado_enc !='')&&(y.tiempo_parado_enc !='N/A')){

                                let hms=y.tiempo_parado_enc;
                                let [hours, minutes, seconds] = hms.split(':');
                                let totalSeconds = (+hours) * 60 * 60 + (+minutes) * 60 + (+seconds);
                                
                                totalSeconds=totalSeconds/3600;
                                //console.log('horas validas:'+totalSeconds);

                                total_pais=parseFloat(total_pais)+parseFloat(totalSeconds);
                                total_pais=parseFloat(total_pais).toFixed(2);
                                // console.log('total pais:'+total_pais);

                              }

                              distancia_total_pais=parseFloat(y.distancia)+parseFloat(distancia_total_pais);

                                if((y.consumo!='')&&(y.consumo!="N/A")&&( parseFloat(y.consumo) >0)){
                                      consumo_total_pais_avl=parseFloat(y.consumo)+parseFloat(consumo_total_pais_avl);
                                }

                             

                          }

                        });


                         me.datos_radial.push(parseFloat(total_pais));
                         me.totalTiempoRalenti=parseFloat(me.totalTiempoRalenti)+parseFloat(total_pais);
                         me.totalTiempoRalenti=parseFloat(me.totalTiempoRalenti).toFixed(2);


                        //almacenamos los datos por pais para graficas

                         distancias_paises.push(parseFloat(distancia_total_pais).toFixed(2));
                         consumo_paises_avl.push(parseFloat(consumo_total_pais_avl).toFixed(2));

                         me.distancia_global=parseFloat(distancia_total_pais)+parseFloat(me.distancia_global);
                         me.distancia_global=parseFloat(me.distancia_global).toFixed(2);

                         me.galones_aprox_global=parseFloat(consumo_total_pais_avl)+parseFloat(me.galones_aprox_global);
                         me.galones_aprox_global=parseFloat(me.galones_aprox_global).toFixed(2);
                     });


                   //  console.log(me.datos_radial);


                   

                           me.chartOptionsRalenti={
                                      chart: {
                                        type: 'polarArea',
                                      },
                                      stroke: {
                                        colors: ['#fff']
                                      },
                                      fill: {
                                        opacity: 0.8
                                      },
                                      /*
                                      responsive: [{
                                        breakpoint: 480,
                                        options: {
                                          chart: {
                                            width: 200
                                          },
                                          legend: {
                                            position: 'bottom'
                                          }
                                        }
                                      }],*/
                                      legend: {
                                            position: 'bottom'
                                          },
                                       dataLabels: {
                                          enabled: true,
                                         },       
                                      labels: me.categorias_paises,
                                      colors: ['#9b59b6', '#1abc9c','#e74c3c', '#2ecc71', '#3498db' ]
                                  };   


                                 me.seriesRalenti=me.datos_radial;
                                 //;

                     //llenamos los datos de combustible


                      me.seriesCombustible= [{
                                                name: 'Distancia km',
                                                data: distancias_paises
                                              },  {
                                                name: 'Combustible ingresado',
                                                data: me.datos_combustible_global
                                              },
                                              {
                                                name: 'Eficiencia',
                                                data: me.eficiencia_paises
                                              }];


           me.chartOptionsCombustible={
            chart: {
              type: 'bar',
              height: 190
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: me.categorias_paises,
            },
            yaxis: {
              title: {
                text: 'Rendimiento'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return " " + val + ""
                }
              }
            }

          };                        
                     
           





                    me.ActualizarDetalleRalenti();
                    me.ActualizarDetalleCombutible();
                   
                }).catch(function(error){
                  console.log(error);
                });





                    


          },

          ActualizarDetalleRalenti(){

            
             let me=this;
             me.itemsRalenti=[];

             this.resumen_global.map(function(y){


                if(y.id_vehiculo){

                          if(me.pais_avl_Ralenti==y.id_resumen_pais._id){
                            me.itemsRalenti.push({'vehiculo':y.id_vehiculo.nombre, 'placa':y.id_vehiculo.placa,'fecha':y.fecha,
                              'tiempo_mov':y.tiempo_mov,'distancia':y.distancia,'tiempo_parado_enc':y.tiempo_parado_enc});
                          }

                }
             });

              me.ocultarLoading();
          },


          listarBoton(){


               this.numeroBotonesValidos=0;
               this.numeroBotonesInValidos=0;
       

               //pedimos los datos al api
               let me=this;
              
           
               let config={headers:{ token:this.token}};
               
                me.mostrarLoading({titulo:'listando datos'});

                  axios.post('eventos-global',{     
                                'desde':this.fecha_inicio,
                                'hasta':this.fecha_fin,
                                'tipo':'Boton'
                              },
                            config
                            ).then(response=>{
                            
                               // console.log('datos Boton globales:'+response.data.length);
                                me.ArregloBotones=response.data;

                                let local_botones=[];
                                let local_botonesInv=[];

                                me.numeroBotonesValidos=0;
                                me.numeroBotonesInValidos=0;

                                me.ArrayconfigPaises.map(function(x){

                                  let cuenta_botonesV=0;
                                  let cuenta_botonesInV=0;

                                     me.ArregloBotones.map(function(y){

                                               if(x._id==y.id_evento_pais){
                                                    
                                                     if(y.valido==true){
                                                         me.numeroBotonesValidos++;
                                                         cuenta_botonesV++;
                                                     }else{
                                                        me.numeroBotonesInValidos++;
                                                        cuenta_botonesInV++;
                                                     }
                                               }

                                              
                                                                      
                                         });


                                         local_botones.push(cuenta_botonesV);
                                         local_botonesInv.push(cuenta_botonesInV);

                                  });

                  



                                  me.seriesBotones= [
                                    {  name: 'Validos', data: local_botones},
                                   { name:'Invalidos', data: local_botonesInv}];


                                   me.chartOptionsBotones= {
                                                  chart: {
                                                    type: 'bar',
                                                    height: 210
                                                  },
                                                  annotations: {
                                                    xaxis: [{
                                                      x: 500,
                                                      borderColor: '#00E396',
                                                      label: {
                                                        borderColor: '#00E396',
                                                        style: {
                                                          color: '#fff',
                                                          background: '#00E396',
                                                        },
                                                        //text: 'X annotation',
                                                      }
                                                    }],
                                                    
                                                  },
                                                  plotOptions: {
                                                    bar: {
                                                      horizontal: false,
                                                    }
                                                  },
                                                  dataLabels: {
                                                    enabled: true
                                                  },
                                                  xaxis: {
                                                    categories: me.categorias_paises,
                                                  },
                                                  colors: ['#e74c3c', '#8A8D93', '#9b59b6', '#1abc9c','#2ecc71'],
                                                  grid: {
                                                    xaxis: {
                                                      lines: {
                                                        show: true
                                                      }
                                                    }
                                                  },
                                                  yaxis: {
                                                    reversed: false,
                                                    axisTicks: {
                                                      show: true
                                                    }
                                                  }

                                                
                                                };

                            if((me.fecha_inicio==me.CalcularFechaHoy())&&(me.fecha_fin==me.CalcularFechaHoy())){

                              if(me.BotonesValidosAntes!=me.numeroBotonesValidos){

                            
                                                // this.$notify('Hello user!');
                                              this.$notify({
                                                    type: 'red',
                                                    duration: 10000,
                                                    speed: 1000,
                                                    group: 'app',
                                                    title: 'Alertas por eventos',
                                                    text: 'Nuevos botones de panico detectados!'
                                                  });

                                               
                                                 me.BotonesValidosAntes=me.numeroBotonesValidos;
                                                   
                                                 //me.playSound();

                            }

                            }

                            

                                     
                                                  
                              

                                  me.ActualizarDetalleBotones();
                                
                                 me.dialogInicio=false;
                             

                         }).catch(e=>{ console.log(e) });

         },

         ActualizarDetalleBotones(){

      
              let me=this;
              this.itemsBotones=[];

              this.ArregloBotones.map(function(x){

                          if((me.pais_avl_Botones==x.id_evento_pais)&&(x.valido==me.switchTipos)){
                            
                                me.itemsBotones.push({'_id':x._id, 'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa,
                                          'nombre_conductor':x.id_conductor.nombre_conductor,'fecha':x.fecha,'evento':x.evento,
                                          'velocidad':x.velocidad, 'latitud':x.latitud,
                                          'longitud':x.longitud, 'observacion':x.observacion, 'valido': x.valido, 
                                          'editado':x.editado});
                          }
                                                                      
                });

            me.ocultarLoading();
         },

         editItemBoton(item){

            this.editedItemBoton = Object.assign({}, item);

            if(this.editedItemBoton.fecha){this.editedItemBoton.fecha=this.getDate(this.editedItemBoton.fecha);}
            this.dialogEditPanico = true;
         },

         saveBoton(){

           
        if(this.editedItemBoton.observacion!=''){

         
              //edicion de evento para ingreso de comentario
  
                    let me=this;
                    let config={headers:{token:me.token}}; 
                    me.mostrarLoading({titulo:'Accediendo a datos'});
                    let id=this.editedItemBoton._id;


                            axios.put(`eventos/${id}`,
                              { 
                                'observacion': me.editedItemBoton.observacion,
                                'editado': me.usuario.nombre_usuario+', a las: '+me.getDate(new Date()),
                                'valido': me.editedItemBoton.valido
                              },
                              config

                                ).then(response=>{
                              
                                console.log(response);

                                 me.listarBoton();
                                 me.dialogEditPanico = false;
                                
                          }).catch(e=>{
                                console.log(e)
                          });

          
      }else{
          alert('debes ingresar observacion');
      }


         },

           ConvertirFecha(fecha_parametro){
             let texto=new Date(fecha_parametro).toLocaleDateString();
            return(texto);

         },





         ActualizarDetalleCombutible(){

           
           
           this.itemsCombustible=[];

          
          
          
          let me=this;

          this.resumen_global.map(function(x){


            if(x.id_vehiculo){

                          if(me.pais_avl_Combustible==x.id_resumen_pais._id){


                            //buscamos los ingresos ese dia especifico en vehiculo especifico

                            let cantidad_total_asig=0;
                            let costo_total_asig=0;
                            let eficiencia_local=0;
                           
                            let array_eficiencias=[];

                              me.combustiblesGlobales.map(function(y){

                                if(y.id_comb_vehiculo._id==x.id_vehiculo._id){

                      
                                        let fecha_res=new Date(x.fecha);
                                        let fecha_arr=new Date(y.fecha);

                                  
                                        if( (fecha_res.getDate()==fecha_arr.getDate())
                                              &&(fecha_res.getMonth()==fecha_arr.getMonth())
                                              &&(fecha_res.getFullYear()==fecha_arr.getFullYear())) {


                                      cantidad_total_asig=parseFloat(y.cantidad)+parseFloat(cantidad_total_asig);
                                      costo_total_asig=parseFloat(costo_total_asig)+parseFloat(y.costo);
                                     
                                      array_eficiencias.push(parseFloat(y.eficiencia).toFixed(2));
                                  }

                              }

                                });




                              if(array_eficiencias.length >0){
                                    eficiencia_local=me.calculateAverage(array_eficiencias);
                              }
                              
                              me.itemsCombustible.push({
                                'vehiculo':x.id_vehiculo.nombre,'placa':x.id_vehiculo.placa, 'fecha':x.fecha, 
                                'distancia':x.distancia,'consumo':x.consumo, 'asignacion':cantidad_total_asig,
                                'eficiencia':eficiencia_local,
                                'costo': costo_total_asig});


                          }

          }


             });



         },


         CalcularFechaHoy(){
          let fecha=new Date();
           fecha.toLocaleString('en-US', { timeZone: 'America/Managua'});
          let mes=fecha.getMonth()+1;
          if(mes<10)mes='0'+mes;
          let dia=fecha.getDate();
          if(dia<10)dia='0'+dia;
          let textofecha=fecha.getFullYear()+'-'+mes+'-'+dia;

          return(textofecha);
        },


        ActualizarDetalleAceite(){

          this.itemsAceite=[];
          let me=this;

            me.ArregloMantenimientos.map(function(x){

                  if((me.pais_avl_Aceite==x.id_pais_mant._id)&&(x.id_tipo_mant.clasificacion=='Aceite')){

                      me.itemsAceite.push(x);
                  }
            });

        },

         ActualizarDetalleLlanta(){

             this.itemsLlantas=[];
             let me=this;

            me.ArregloMantenimientos.map(function(x){

                  if((me.pais_avl_Llanta==x.id_pais_mant._id)&&(x.id_tipo_mant.clasificacion=='llantas')){

                      me.itemsLlantas.push(x);
                  }
            });


         },

          ActualizarDetalleFreno(){

           this.itemsFreno=[];
           let me=this;

            me.ArregloMantenimientos.map(function(x){

                  if((me.pais_avl_Freno==x.id_pais_mant._id)&&(x.id_tipo_mant.clasificacion=='Frenos')){

                      me.itemsFreno.push(x);
                  }
            });


         },

         CambiarVistaPais(){

                this.$router.push('/dashpais'); 

         },

         calculateAverage(array) {
                let total = 0;
                let count = 0;
                let average=0;
                for (let iterar=0; iterar < array.length; iterar++){
                      total =parseFloat(array[iterar])+parseFloat(total);
                      count++;
                  }

                  console.log('total calculate average:'+total+', cantidad:'+count);

                  average=total / count;
                return parseFloat(average).toFixed(2);
            },

         playSound () {
           const sound = require('@/assets/alerta.wav');
          var audio = new Audio(sound);
          audio.play();
        }

    }
}
</script>

<style>

.my-notification {
  margin-left:55px;
  padding: 10px;
  font-size: 12px;
}

.container{
     max-width: 100vw;
     padding:0px;
  }

</style>
